import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import moment from 'moment';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
  ATHLETE,
  BRAND,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  ResponsiveImage,
  H2,
  IconCard,
  Modal,
  Button,
  AvatarLarge,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import SectionHero from './SectionHero';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';

import css from './ListingPage.module.css';
import { getUserType } from '../../util/helper.js';
import {
  iconData,
  lifestyleInterestData,
  optionsData,
  optionsDatafi,
  sponsorshipTypeData,
  sponsorshipTypeDataFi,
  sportsData,
  sportsDataFi,
  termsAndConditionsData,
  termsAndConditionsDataFi,
} from '../../config/configListing.js';
import InquiryForm from './InquiryForm/InquiryForm.js';
import { listingFieldFi } from '../../util/hostedListingFieldsFi.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
  } = props;

  const userType = getUserType(currentUser);
  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const { socialAccountsData } = currentListing?.author?.attributes?.profile?.publicData || {};
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const {
    listingType,
    brandDescription,
    companyName,
    athleteDescription,
    teamName,
    location: userLocation,
    lifestyleInterest,
    sports,
    brandSponsorshipType,
    brand_ambassadorPrice,
    logo_visibilityPrice,
    social_mediaPrice,
    appearancePrice,
    eventsPrice,
    selectedTermsAndConditions,
    activeIcons,
    timeDuration_right_to_image,
    timeDuration_must_not_delete_social_media_post_before,
    preferenceType,
    appearanceDescription,
    brand_ambassadorDescription,
    eventsDescription,
    logo_visibilityDescription,
    social_mediaDescription,
    brandPrice,
    eventDate,
    eventsName,
  } = publicData || {};


  const language = typeof window != 'undefined' && localStorage.getItem('language');
  const selectedTermsAndConditionsData =
    language === 'Finnish' ? termsAndConditionsDataFi : termsAndConditionsData;
  const sponsorshipTypeDatadynamic =
    language === 'Finnish' ? sponsorshipTypeDataFi : sponsorshipTypeData;
  const optionsDataDatadynamic = language === 'Finnish' ? optionsDatafi : optionsData;
  const lifestyle_Interestdynamic =
    language === 'Finnish' ? listingFieldFi : lifestyleInterest ?? [];
  // const sortedSportsDataFi = sportsDataFi.slice().sort((a, b) => a.label.localeCompare(b.label, 'fi'));

  const lifestyleInterestLabel = lifestyle_Interestdynamic
    .filter(data => {
      return data?.key === 'lifestyleInterest';
    })
    ?.map(elem => {
      return elem?.enumOptions
        ?.map(item => {
          if (lifestyleInterest?.includes(item?.option)) {
            return item?.label;
          }
        })
        .filter(label => label !== undefined);
    });

  const lifestyle_Interest =
    language === 'Finnish'
      ? lifestyleInterestLabel.flat().join(', ')
      : lifestyleInterest?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ');
  const sportsDataDatadynamic = language === 'Finnish' ? sportsDataFi : sportsData;
  const termsAndConditionMonths = selectedTermsAndConditionsData?.[0]?.data.map(itm => itm);
  const result = termsAndConditionMonths.find(item => item.key === timeDuration_right_to_image);
  const monthslabel = result ? result.label : null;

  const termsAndConditions = selectedTermsAndConditionsData
    .filter(data => selectedTermsAndConditions?.includes(data?.key))
    .map(data => data?.label);

  const sponsorTypes = sponsorshipTypeDatadynamic
    .filter(data => brandSponsorshipType?.includes(data?.key))
    .map(data => data?.label)
    .join(',');

  const icons = iconData
    .filter(data => activeIcons?.includes(data?.key))
    .map(data => data?.label)
    .join(',');

  const preferenceTypeLabel = optionsDataDatadynamic
    .filter(data => preferenceType?.includes(data?.key))
    .map(data => data?.label)
    .join(',');

  const sportsLabel = sportsDataDatadynamic
    .filter(data => sports?.includes(data?.key))
    .map(data => data?.label)
    .join(',');

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function

  // const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const authorDisplayName = ensuredAuthor?.attributes?.profile?.publicData?.userName;
  const companyDisplayName = ensuredAuthor?.attributes?.profile?.publicData?.companyName;
  const { formattedPrice } = priceData(
    brandPrice ? new Money(brandPrice * 100, price.currency) : price,
    config.currency,
    intl
  );
  const logoPriceF = logo_visibilityPrice ? new Money(logo_visibilityPrice, config.currency) : null;
  const formattedLogoVisibilityPrice = logoPriceF ? formatMoney(intl, logoPriceF) : null;

  const postPriceF = social_mediaPrice ? new Money(social_mediaPrice, config.currency) : null;
  const formattedSocialMediaPrice = postPriceF ? formatMoney(intl, postPriceF) : null;

  const appearancePriceF = appearancePrice ? new Money(appearancePrice, config.currency) : null;
  const formattedApperancePrice = appearancePriceF ? formatMoney(intl, appearancePriceF) : null;
  //   const newPrice = formattedApperancePrice = formattedApperancePrice && formattedApperancePrice.replace(' €', '').replace(/\./g, '').replace(',', '.');

  // // Convert to a number
  // let priceNumber = parseFloat(newPrice) * 100;

  // console.log(priceNumber); // Output: 789500

  const ambassadorPriceF = brand_ambassadorPrice
    ? new Money(brand_ambassadorPrice, config.currency)
    : null;
  const formattedBrandAmbassadorPrice = ambassadorPriceF
    ? formatMoney(intl, ambassadorPriceF)
    : null;

  const eventsPriceF = eventsPrice ? new Money(eventsPrice, config.currency) : null;
  const formattedEventsPrice = eventsPriceF ? formatMoney(intl, eventsPriceF) : null;

  const priceArray = [
    { key: 'logo_visibilityPrice', label: 'Logo', price: logoPriceF },
    { key: 'social_mediaPrice', label: 'Social Media', price: postPriceF },
    { key: 'brand_ambassadorPrice', label: 'Brand Ambassador', price: ambassadorPriceF },
    { key: 'appearancePrice', label: 'Appearance', price: appearancePriceF },
    { key: 'eventsPrice', label: 'Event', price: eventsPriceF },
  ];

  const priceArrayfi = [
    { key: 'logo_visibilityPrice', label: 'Logonäkyvyys', price: logoPriceF },
    { key: 'social_mediaPrice', label: 'Sosiaalinen media', price: postPriceF },
    { key: 'brand_ambassadorPrice', label: 'Brändilähettiläs', price: ambassadorPriceF },
    { key: 'appearancePrice', label: 'Esiintyminen', price: appearancePriceF },
    { key: 'eventsPrice', label: 'Tapahtuma', price: eventsPriceF },
  ];

  const priceArraydynamic = language === 'Finnish' ? priceArrayfi : priceArray;

  const handleModal = (price, key) => {
    if (price && price.amount && key) {
      const initialValues = {
        deliverableItems: [key],
        partnershipStartLength: [],
        partnershipLength: '',
        inquiryPrice: price,
   
      };

      setInquiryModalOpen(initialValues);
    }
  };

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const user = currentListing?.author;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName } = ensuredUser?.attributes?.profile;

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation();
    setImageCarouselOpen(true);
  };
  const hasImages = currentListing.images && currentListing.images.length > 0;
  const firstImage = hasImages ? currentListing.images[0] : null;
  const { variantPrefix = 'square-small2x' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  const userName = currentListing?.author?.attributes?.profile?.abbreviatedName;
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {listingType == BRAND ? (
          <div className={css.mainTopWrapper}>
            <div className={css.sectionHeroWrapper}>
              <SectionHero
                isAuthenticated={isAuthenticated}
                title={title}
                listing={currentListing}
                currentUser={currentUser}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
                imageCarouselOpen={imageCarouselOpen}
                onImageCarouselClose={() => setImageCarouselOpen(false)}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.avatarWrapper}>
                <AvatarLarge user={currentListing?.author} className={css.avatarDesktop} />
                <div className={css.userName}>{companyDisplayName}</div>
              </div>

              <div className={css.mobileButtonWrapper}>
                <SectionAuthorMaybe
                  title={sponsorTypes}
                  className={css.sectionAuthor}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={onContactUser}
                  listingType={listingType}
                  userType={userType}
                  config={config}
                  intl={intl}
                  priceArray={priceArraydynamic}
                  isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                  onCloseInquiryModal={() => setInquiryModalOpen(false)}
                  sendInquiryError={sendInquiryError}
                  sendInquiryInProgress={sendInquiryInProgress}
                  onSubmitInquiry={onSubmitInquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
                <div>
                  {listingType === BRAND ? (
                    <NamedLink
                      className={css.viewProfileButton}
                      name="ProfilePage"
                      params={{ id: ensuredUser && ensuredUser?.id?.uuid }}
                    >
                      <FormattedMessage id="UserCard.viewProfileLink" />
                    </NamedLink>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={css.rightDataBox}>
              <ul>
                {companyName ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.brandName" />
                    </span>
                    <span className={css.titleNormal}>{companyName}</span>
                  </li>
                ) : null}

                {teamName ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingDetailsForm.teamName" />
                    </span>
                    <span className={css.titleNormal}>{teamName}</span>
                  </li>
                ) : null}
                {athleteDescription ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.description" />
                    </span>
                    <span className={css.titleNormal}>{athleteDescription}</span>
                  </li>
                ) : null}
                {brandDescription ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.camapignDescription" />
                    </span>
                    <span className={css.titleNormal}>{brandDescription}</span>
                  </li>
                ) : null}

                {userLocation?.address ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.location" />
                    </span>
                    <span className={css.titleNormal}>{userLocation?.address}</span>
                  </li>
                ) : null}

                {lifestyleInterest ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.lifestyleInterest" />
                    </span>
                    <span className={css.titleNormal}>{lifestyle_Interest}</span>
                  </li>
                ) : null}
                {listingType === BRAND && brandSponsorshipType ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.sponsorshiptype" />
                    </span>
                    <span className={css.titleNormal}>{sponsorTypes}</span>
                  </li>
                ) : null}
                {activeIcons && activeIcons.length !== 0 ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingLocationForm.socialMediaChannels" />
                    </span>
                    <span className={css.titleNormal}>{icons}</span>
                  </li>
                ) : null}
                {selectedTermsAndConditions ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingAboutForm.termsAndConditionsLabel" />
                    </span>
                    <ul className={css.titleTerms} style={{ whiteSpace: 'pre-line' }}>
                      {termsAndConditions?.map(item => {
                        return (
                          <li>
                            {item}
                            <strong>
                              {item === 'Rights to image and name use for' ||
                                item === 'Oikeus kuviin ja nimen käyttöön'
                                ? monthslabel
                                : item === 'Must not delete social media post before'
                                  ? timeDuration_must_not_delete_social_media_post_before
                                  : null}
                            </strong>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : null}

                {preferenceType ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.lookingForLabel" />
                    </span>
                    <span className={css.titleNormal}>{preferenceTypeLabel}</span>
                  </li>
                ) : null}
                {sports ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="CustomExtendedDataField.prefferedSportLabel" />
                    </span>
                    <span className={css.titleNormal}>{sportsLabel}</span>
                  </li>
                ) : null}

                {price ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage
                        id={
                          listingType === BRAND
                            ? 'EditListingListingPage.value'
                            : 'EditListingPricingAndStockForm.pricePerProduct'
                        }
                      />
                    </span>
                    <span className={css.titleNormal}>{formattedPrice}</span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <div className={css.athleteListingWrapper}>
              <div className={css.athleteMainTopWrapper}>
                <div className={css.athleteSectionHeroWrapper}>
                  <SectionHero
                    isAuthenticated={isAuthenticated}
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingPathParamType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={imageCarouselOpen}
                    onImageCarouselClose={() => setImageCarouselOpen(false)}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
                <div className={css.athleterightDataBox}>
                  <ul>
                    {listingType === BRAND && title ? (
                      <li>
                        {/* <span className={css.titleBolds}>
                            <FormattedMessage id="EditListingPage.brandName" />
                          </span> */}
                        <span className={css.titleBolds}>{title}</span>
                      </li>
                    ) : null}

                    {teamName ? (
                      <li>
                        {/* <span className={css.titleBolds}>
                            <FormattedMessage id="EditListingDetailsForm.teamName" />
                          </span> */}
                        <span className={css.teamNameHeading}>{teamName}</span>
                      </li>
                    ) : null}

                    {brandDescription ? (
                      <li>
                        {/* <span className={css.titleBolds}>
                            <FormattedMessage id="EditListingPage.camapignDescription" />
                          </span> */}
                        <span className={css.titleBolds}>{brandDescription}</span>
                      </li>
                    ) : null}

                    {userLocation ? (
                      <li>
                        {/* <span className={css.titleBolds}>
                            <FormattedMessage id="EditListingListingPage.location" />
                          </span> */}
                        <span className={css.titleBolds}>{userLocation?.address}</span>
                      </li>
                    ) : null}

                    {sports ? (
                      <li>
                        {/* <span className={css.titleBolds}>
                            <FormattedMessage id="EditListingListingPage.location" />
                          </span> */}
                        <span className={css.titleBolds}>{sportsLabel}</span>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.athleteSocailBox}>
              <div>
                {athleteDescription ? (
                  <div>
                    <div className={css.aboutTitle}>
                      <FormattedMessage id="EditListingPage.description" />
                    </div>
                    <div className={css.aboutNormals}>{athleteDescription}</div>
                  </div>
                ) : null}
              </div>

              {lifestyleInterest ? (
                <div>
                  <div className={css.aboutTitle}>
                    <FormattedMessage id="EditListingListingPage.lifestyleInterest" />
                  </div>
                  <span className={css.aboutNormals}>{lifestyle_Interest}</span>
                </div>
              ) : null}
              {/* {socialAccountsData ? (
                  <div className={css.socialmediaAccount}>
                    <p>Social media accounts</p>
                    {socialAccountsData.map(item => {
                      console.log('item :>> ', item);
                      return (
                        <div className={css.socialMediaWrapper}>

                          <div className={css.followerCount}><span></span>{item?.reputation?.follower_count}</div>
                          <div className={css.followerCount}><span></span></div>
                          <div className={css.followerCount}><span></span></div>
                        </div>
                      )
                    })}
                  </div>
                ) : null} */}
              <div className={css.socialHeading}>
                <p>
                  <FormattedMessage id="EditListingPresencePanel.Phyllo.socialMediaAccounts" />
                </p>
              </div>
              <div className={css.socialRow}>
                <div className={css.leftBox}>
                  <ul>
                    <li>
                      <FormattedMessage id="EditListingPresencePanel.Phyllo.followerCount" />
                      :-
                    </li>
                    {/* <li><FormattedMessage id="EditListingPresencePanel.Phyllo.followingCount"/>:-</li>
                      <li><FormattedMessage id="EditListingPresencePanel.Phyllo.contentCount"/>:-</li> */}
                  </ul>
                </div>
                <div className={css.rightBox}>
                  <div className={css.socialImageWrapper}>
                    {socialAccountsData &&
                      socialAccountsData?.length &&
                      socialAccountsData?.map(item => {
                        return (
                          <div className={css.socialMediaImage}>
                            <a href={item.url} target="blank">
                              <img src={item?.work_platform?.logo_url} />
                            </a>
                          </div>
                        );
                      })}
                  </div>
                  <div className={css.socialmediaData}>
                    {socialAccountsData &&
                      socialAccountsData?.length &&
                      socialAccountsData?.map(item => {
                        return (
                          <ul>
                            <li>{item?.reputation?.follower_count}</li>
                            {/* <li>{item?.reputation?.following_count}</li>
                            <li>{item?.reputation?.content_count}</li> */}
                          </ul>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className={css.athletePriceWrapper}>
                {logo_visibilityPrice ? (
                  <div className={css.priceListBox}>
                    <div>
                      <div className={css.titlePrice}>
                        <FormattedMessage
                          id={
                            listingType === BRAND
                              ? 'EditListingListingPage.value'
                              : 'EditListingPage.logo'
                          }
                        />
                      </div>
                      <div className={css.titleResult}>
                        <span className={css.fromText}>
                          <FormattedMessage id="ListingPage.from" />
                        </span>
                        {formattedLogoVisibilityPrice}
                      </div>
                      <div className={css.priceDescription}>
                        {logo_visibilityDescription ? logo_visibilityDescription : null}
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={userType === ATHLETE}
                        onClick={() => handleModal(logoPriceF, "logo_visibilityPrice")}
                        type="submit"
                      >
                        {' '}
                        <FormattedMessage id="EditListingPage.priceButton" />
                      </button>
                    </div>
                  </div>
                ) : null}
                {appearancePrice ? (
                  <div className={css.priceListBox}>
                    <div>
                      <div className={css.titlePrice}>
                        <FormattedMessage id="EditListingPage.apperance" />
                      </div>
                      <div className={css.titleResult}>
                        <span className={css.fromText}>
                          <FormattedMessage id="ListingPage.from" />{' '}
                        </span>
                        {formattedApperancePrice}
                      </div>
                      <div className={css.priceDescription}>
                        {appearanceDescription ? appearanceDescription : null}
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={userType === ATHLETE}
                        onClick={() => handleModal(appearancePriceF, "appearancePrice")}
                        type="submit"
                      >
                        <FormattedMessage id="EditListingPage.priceButton" />
                      </button>
                    </div>
                  </div>
                ) : null}
                {social_mediaPrice ? (
                  <div className={css.priceListBox}>
                    <div>
                      <div className={css.titlePrice}>
                        <FormattedMessage id="EditListinPage.socialMedia" />
                      </div>
                      <div className={css.titleResult}>
                        <span className={css.fromText}>
                          <FormattedMessage id="ListingPage.from" />
                        </span>{' '}
                        {formattedSocialMediaPrice}
                      </div>
                      <div className={css.priceDescription}>
                        {' '}
                        {social_mediaDescription ? social_mediaDescription : null}
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={userType === ATHLETE}
                        onClick={() => handleModal(postPriceF, "social_mediaPrice")}
                        type="submit"
                      >
                        <FormattedMessage id="EditListingPage.priceButton" />
                      </button>
                    </div>
                  </div>
                ) : null}
                {brand_ambassadorPrice ? (
                  <div className={css.priceListBox}>
                    <div>
                      <div className={css.titlePrice}>
                        <FormattedMessage id="EditListingPage.ambassador" />
                      </div>
                      <div className={css.titleResult}>
                        <span className={css.fromText}>
                          <FormattedMessage id="ListingPage.from" />{' '}
                        </span>
                        {formattedBrandAmbassadorPrice}
                      </div>
                      <div className={css.priceDescription}>
                        {brand_ambassadorDescription ? brand_ambassadorDescription : null}
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={userType === ATHLETE}
                        onClick={() => handleModal(ambassadorPriceF, "brand_ambassadorPrice")}
                        type="submit"
                      >
                        <FormattedMessage id="EditListingPage.priceButton" />
                      </button>
                    </div>
                  </div>
                ) : null}
                {eventsPrice ? (
                  <div className={css.priceListBox}>
                    <div>
                      <div className={css.titlePrice}>
                        <FormattedMessage id="EditListingPage.event" />
                      </div>
                      <div className={css.eventWrapper}>
                        <span>{eventsName}</span>
                        <span>{moment(eventDate).format('DD/MM/YYYY')}</span>
                      </div>
                      <div className={css.titleResult}>
                        <span className={css.fromText}>
                          <FormattedMessage id="ListingPage.from" />{' '}
                        </span>{' '}
                        {formattedEventsPrice}
                      </div>
                      <div className={css.priceDescription}>
                        {eventsDescription ? eventsDescription : null}
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={userType === ATHLETE}
                        onClick={() => handleModal(eventsPriceF, "eventsPrice")}
                        type="submit"
                      >
                        <FormattedMessage id="EditListingPage.priceButton" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <div className={css.contentWrapperForHeroLayout}>
          <div className={css.mainColumnForHeroLayout}>
            <div className={css.mobileHeading}>
              {/* <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4> */}
            </div>
            {/* <div className={css.bannerImage}>
              <div className={css.portfolioHeading}>Portfolio</div>
              <div className={css.portfolioGallery}>
                <ResponsiveImage
                  alt={title}
                  image={currentAuthor?.profileImage}
                  variants={variants}
                />
              </div>
            </div> */}

            {/* <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null || includeForListingTypes.includes(listingType);

              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value != null;
              return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value || []}
                  />,
                ]
                : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                  : pickedElements;
            }, [])} */}

            {/* <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            /> */}
            {listingType === ATHLETE ? (
              <SectionAuthorMaybe
                title={title}
                className={css.sectionAuthor}
                listing={currentListing}
                authorDisplayName={authorDisplayName}
                onContactUser={onContactUser}
                config={config}
                intl={intl}
                listingType={listingType}
                userType={userType}
                priceArray={priceArraydynamic}
                isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                onCloseInquiryModal={() => setInquiryModalOpen(false)}
                sendInquiryError={sendInquiryError}
                sendInquiryInProgress={sendInquiryInProgress}
                onSubmitInquiry={onSubmitInquiry}
                currentUser={currentUser}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            ) : null}

            {listingType === ATHLETE ? (
              <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            ) : null}
          </div>
          <div></div>

          {/* <div className={css.orderColumnForHeroLayout}>
            <OrderPanel
              className={css.orderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
            />
          </div> */}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message, inquiryDetails) =>
    dispatch(sendInquiry(listing, message, inquiryDetails)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
