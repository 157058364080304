import React, { useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { ATHLETE, BRAND, SPORTTEAM, propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconInquiry,
  Heading,
  FieldCheckboxGroup,
  FieldSelect,
  FieldCheckbox,
  FieldCurrencyInput,
  FieldRadioButton,
} from '../../../components';

import css from './InquiryForm.module.css';
import {
  partnershipLength,
  partnershipLengthFi,
  partnershipStartLength,
  partnershipStartLengthFi,
  sponsorshipTypeData,
  sponsorshipTypeDataFi,
} from '../../../config/configListing';
import appSettings from '../../../config/settings';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney } from '../../../util/currency';
import { useSelector } from 'react-redux';
import { ensureCurrentUser } from '../../../util/data';

const { UUID, Money } = sdkTypes;

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

// NOTE: this InquiryForm is only for booking & purchase processes
// The default-inquiry process is handled differently
const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendInquiryError,
        priceArray,
        listingType,
        userType,
        form,
        listing,
        config,
        values,
        invalid,
        currentUser,
      } = fieldRenderProps; 
      const { athleteNumber, clubBankAccount } =
        currentUser?.attributes?.profile?.protectedData || {};
      const { clubEmailAddress } = currentUser?.attributes?.profile?.publicData || {};
      const { stripeConnected } = currentUser?.attributes || {};
      const [paymentPreference, setPaymentPreference] = useState(() =>
        stripeConnected && clubBankAccount ? '' : stripeConnected ? 'stripe' : 'club'
      );
      
      useEffect(()=>{
        form.change('paymentPreference',paymentPreference)
      })
      const paymentPerformanceLabel = intl.formatMessage({ id: 'PaymentPageForm.PaymentMethod' });
      const paymentPerformanceStripe = intl.formatMessage({
        id: 'PaymentPageForm.PaymentMethodPreferenceStripe',
      });
      const paymentPerformanceClub = intl.formatMessage({
        id: 'PaymentPageForm.PaymentMethodPreferenceClub',
      });
      const paymentInformation = intl.formatMessage({
        id: 'PaymentPageForm.PaymentMethodSelectedByAthlete',
      });
      const unitType = listing?.attributes?.publicData?.unitType;
      const marketplaceCurrency = config?.currency;
      const messageLabel = intl.formatMessage(
        {
          id: 'InquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'InquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'InquiryForm.messageRequired',
      });

      const fromdata = intl.formatMessage({
        id: 'ListingPage.from',
      });

      const currentUserListing = useSelector(state => state?.user?.currentUserListing);
      const teamName =
        userType === ATHLETE ? currentUserListing?.attributes?.publicData?.teamName : null;
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const brandDisableFields =
        userType === BRAND &&
        (!values?.deliverableItems?.length ||
          !values?.partnershipStartLength?.length ||
          !values?.partnershipLength);
      const submitDisabled =
        invalid ||
        submitInProgress ||
        brandDisableFields ||
        !values?.inquiryPrice?.amount > 25000 ||
        !values.message;
      //const submitDisabled = submitInProgress || !values.message;

      const deliverableItemsProps = {
        id: 'deliverableItems',
        name: 'deliverableItems',
        label: <FormattedMessage id="NegotiationInquiryForm.deliverableItems" />,
        options: priceArray && priceArray.length ? [...priceArray] : [],
        twoColumns: true,
      };

      const language = typeof window != 'undefined' && localStorage.getItem('language');
      const partnershipStartLengthdynamic =
        language === 'Finnish' ? partnershipStartLengthFi : partnershipStartLength;
      const dynamicsponsorshipTypeData =
        language === 'Finnish' ? sponsorshipTypeDataFi : sponsorshipTypeData;

      const sponsorType = dynamicsponsorshipTypeData.find(data => {
        if (data.key === listingTitle) return data;
      });

      const partnershipStartLengthProps = {
        id: 'partnershipStartLength',
        name: 'partnershipStartLength',
        label: <FormattedMessage id="NegotiationInquiryForm.partnershipStartLength" />,
        // label: "When would you like partnership to start ?*",
        options: [...partnershipStartLengthdynamic],
        twoColumns: true,
      };

      const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
        const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
        const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
        const priceRequired = validators.required(priceRequiredMsg);

        const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
        const minPrice = formatMoney(intl, minPriceRaw);
        const priceTooLowMsgId = { id: 'EditListingPricingAndStockForm.priceTooLow' };
        const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          priceTooLowMsg,
          listingMinimumPriceSubUnits
        );

        return listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;
      };

      const listingMinimumPriceSubUnits = 25000;
      const priceValidators = getPriceValidators(
        listingMinimumPriceSubUnits,
        marketplaceCurrency,
        intl
      );

      const dynamicPartnershipData =
        language === 'Finnish' ? partnershipLengthFi : partnershipLength;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          {userType === 'brand' ? (
            <Heading as="h2" rootClassName={css.heading}>
              <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
            </Heading>
          ) : (
            <Heading as="h2" rootClassName={css.heading}>
              <FormattedMessage id="InquiryForm.headingAthlete" values={{ teamName }} />
            </Heading>
          )}
          <FieldTextInput
            className={css.quantityField}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          <div>
            {listingType === ATHLETE ? (
              <>
                {/* <div className={css.patnershipHeading}>
                  <FieldCheckboxGroup {...deliverableItemsProps} />
                </div> */}
                <label>
                  <FormattedMessage id="NegotiationInquiryForm.deliverableItems" />
                </label>
                <div className={css.patnershipHeading}>
                  {priceArray.map((item, index) => {
                    const { price } = item;
                    const priceIs = price ? formatMoney(intl, price) : null;
                    return (
                      <FieldCheckbox
                        id={`${index}.deliverableItems`}
                        name="deliverableItems"
                        label={priceIs ? item?.label + ` ${fromdata} ` + priceIs : item.label}
                        value={item.key}
                        required={true}
                      />
                    );
                  })}
                </div>

                <FieldSelect
                  id={'partnershipLength'}
                  className={css.quantityField}
                  name="partnershipLength"
                  label={intl.formatMessage({ id: 'NegotiationInquiryForm.partnershipLength' })}
                >
                  <option disabled value="">
                    {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
                  </option>
                  {dynamicPartnershipData.map(pt => (
                    <option key={pt.key} value={pt.key}>
                      {pt.label}
                    </option>
                  ))}
                </FieldSelect>

                <div className={css.patnershipStartBox}>
                  <FieldCheckboxGroup {...partnershipStartLengthProps} />
                </div>
              </>
            ) : null}
          </div>
          <div>
            <FieldCurrencyInput
              id={`${formId}inquiryPrice`}
              name="inquiryPrice"
              className={css.priceInput}
              label={intl.formatMessage({ id: 'NegotiationForm.Price' }, { unitType })}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingAndStockForm.priceInputPlaceholder',
              })}
              currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
              validate={priceValidators}
            />
            <input
              name="paymentPreference"
              type="hidden"
              value={paymentPreference}
            />
            { [ATHLETE,SPORTTEAM].includes(userType)? (
              athleteNumber && clubBankAccount && clubEmailAddress && stripeConnected ? (
                <div className={css.paymentRadioBox}>
                  <label className={css.paymentPerformanceLabel}>{paymentPerformanceLabel}</label>
                  <div className={css.radioWrapper}>
                    {/* <label>{paymentPerformanceStripe}</label> */}
                    <FieldRadioButton
                      id="paymentPerformanceStripe"
                      name="paymentPerformance"
                      value="stripe"
                      label={intl.formatMessage({
                        id: 'PaymentPageForm.PaymentMethodPreferenceStripe',
                      })}
                      onClick={e => {
                        console.log(e.target.value);
                        setPaymentPreference(e.target.value);
                      }}
                    />
                  </div>
                  <div className={css.radioWrapper}>
                    {/* <label>{paymentPerformanceClub}</label> */}
                    <FieldRadioButton
                      id="paymentPerformanceClub"
                      name="paymentPerformance"
                      value="club"
                      label={intl.formatMessage({
                        id: 'PaymentPageForm.PaymentMethodPreferenceClub',
                      })}
                      onClick={e => {
                        console.log(e.target.value);
                        setPaymentPreference(e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendInquiryError} />
            <PrimaryButton
              className={css.sendButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="InquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;
